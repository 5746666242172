<template>
	<div
		class="contain"
		v-infinite-scroll="load"
		:infinite-scroll-disabled="finish"
	>
		<searchBar :searchInput="false"></searchBar>
		<div class="bgf mb_10">
			<div class="title bottom_link flex">
				<div class="fs_18 color_333 flex_start">
					<span>{{ detail.reward && detail.reward.reward_name }}</span>
				</div>
				<div class="flex_end">
					<div class="sub_color fs_12 mr_10">接单人</div>
					<div class="color fs_20 bold" style="line-height:22px">
						{{ (detail.user && detail.user.realname) || "未知" }}
					</div>
				</div>
			</div>
			<div class="bottom fs_14">
				<el-row>
                    <el-col :span="7">
						<span class="label sub_color">任务编号：</span>
						<span class="color_333">{{
							detail.reward && detail.reward.reward_no
						}}</span>
					</el-col>
					<el-col :span="6">
						<span class="label sub_color">计价方式：</span>
						<span class="color_red fs_16">{{
							detail.reward && detail.reward.settle_info
						}}</span>
					</el-col>
					<el-col :span="6">
						<span class="label sub_color">结算周期：</span>
						<span class="color_333">{{
							detail.reward && detail.reward.price_type
						}}</span>
					</el-col>
					<el-col :span="5">
						<span class="label sub_color">结算形式：</span>
						<span class="color_333">{{
							detail.reward && detail.reward.settle_type
						}}</span>
					</el-col>

				</el-row>
				<el-row class="mt_10">
					<el-col :span="7">
						<span class="label sub_color">接单主体：</span>
						<span class="color_333"
							>{{ detail.reward && detail.reward.role_limit_text }}
						</span>
					</el-col>
					<el-col :span="6">
						<span class="label sub_color">联系人：</span>
						<span class="color_333">{{
							detail.reward && detail.reward.publish_contacts
						}}</span>
					</el-col>
					<el-col :span="6">
						<span class="label sub_color">联系方式：</span>
						<span class="color_333">{{
							detail.reward && detail.reward.publish_tel
						}}</span>
					</el-col>
					<el-col :span="5">
						<span class="label sub_color">发布人：</span>
						<span class="color_333">{{
							detail.reward && detail.reward.publish_user_name
						}}</span>
					</el-col>
				</el-row>
				<el-row class="mt_10">
                    <el-col :span="7">
						<span class="label sub_color">起止时间：</span>
						<span class="color_333">{{
							detail.reward && detail.reward.publish_date
						}}</span>
					</el-col>
					<el-col :span="6">
						<span class="label sub_color">任务状态：</span>
						<span class="color_333">{{ detail.status_text }}</span>
					</el-col>

					<el-col :span="6">
						<span class="label sub_color">累计收入金额：</span>
						<span class="fs_20 bold color">￥{{ detail.income_amount }}</span>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="bgf" v-if="list.length != 0">
			<div class="list">
				<div
					class="item flex bottom_link"
					v-for="(item, index) in list"
					:key="index"
				>
					<div class="left flex">
						<el-avatar
							shape="circle"
							:size="50"
							:fit="'contain'"
							:src="item.user && item.user.avatar"
							class="mr_20"
						>
							<img :src="$store.state.baseAvatar" />
						</el-avatar>
						<div class="flex_col">
							<div class=" color_333  flex_c">
								<span class="mr_10 fs_20 bold">{{ item.user && item.user.realname }}</span>
                                <span class="tag fs_12 center" :class="{in: item.has_invoice!=0}">{{ item.has_invoice==0?'未申请开票':'已申请开票' }}</span>
							</div>
							<p class="fs_14 mt_10">
								<span class="sub_color label">接单流水号：</span>
								<span class="color_333">{{ item.apply.apply_no }}</span>
							</p>
						</div>
					</div>
					<div class="right flex userinfo_tips">
						<div class="flex_col fs_12 ritem line center">
							<p class="sub_color mb_8">记录时间</p>
							<p class="">{{ item.update_time }}</p>
						</div>
						<div class="flex_col fs_12 ritem line center">
							<p class="sub_color mb_8">记录人</p>
							<p class="">{{ item.op_user }}</p>
						</div>
                        <div class="flex_col fs_12 ritem center">
							<p class="mb_8 fs_12">￥<span class="fs_20 bold">{{ item.total_amount }}</span></p>
							<p class="btn flex_cen" v-if="!item.upload_image" @click="upload(item)">{{ status == 1 ? '上传' : '未上传' }}支付凭证</p>
							<p class="btn flex_cen in" v-else @click="payImg=item.upload_image;dialogVisible=true;">查看支付凭证</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<empty :finish="finish" :length="list.length" v-else></empty>

        <pay-msg :id="payId" :type="1" ref="paymsg" @init="init"></pay-msg>

        <el-dialog v-model="dialogVisible" :lock-scroll="true" width="492px" top="20vh">
            <img :src="payImg" alt="">
        </el-dialog>
	</div>
</template>

<script>
import payMsg from "./dialog/pay-msg"
export default {
	name: "EmploymentPayList",
	components: { payMsg },
	data() {
		return {
			dialogVisible: false,
			reward_id: "",
			id: "",
			list: [],
			page: 1,
			limit: 10,
			finish: false,
			detail: {},
			url: require("@/assets/imgs/index/2.png"),
			payImg: '',
			payId: '',
			status: 1
		}
	},
	created() {
		this.reward_id = this.$route.params.reward_id
		this.id = this.$route.params.id
		this.payId = this.$route.params.id
		this.status = this.$route.params.status
		this.getDetail()
		this.getList()
	},
	methods: {
		init() {
			this.page = 1
			this.list = []
			this.finish = false
			this.getList()
		},
		load() {
			this.page++
			this.getList()
		},
		getList() {
			this.$axios({
				url: "/ent/v3/income",
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					reward_id: this.reward_id,
					apply_id: this.id
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = this.list.concat(res.data.list)
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		getDetail() {
			this.$axios({
				url: `/ent/v3/apply/${this.id}`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					this.detail = res.data.apply
				}
			})
		},
		upload(item) {
			if(this.status == 2)return
			this.payId = item.id
			this.$refs.paymsg.form.price = item.total_amount
			this.$refs.paymsg.dialogVisible = true
		}
	}
}
</script>
<style lang="less" scoped>
.loading {
	padding-top: 30px;
}
.userinfo_tips {
	position: relative;
	.line:not(:last-child) {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			width: 1px;
			height: 50px;
			background: #ededed;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
.mb_8 {
	margin-bottom: 8px;
}
.flex_end {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.color_red {
	color: #ff4e4e;
}
.title {
	padding: 25px 30px 20px 30px;
	border-radius: 2px;
}
.bottom {
	padding: 20px 30px;
	.label {
		width: 100px;
		display: inline-block;
	}
}
.list {
	.item {
		padding: 10px 40px 10px 30px;
		height: 130px;
		.left {
			.label {
				min-width: 95px;
			}
		}
		.right {
			.ritem {
				width: 160px;
			}
		}
        .tag {
            width: 80px;
            height: 20px;
            background: #FBFBFB;
            border: 1px solid #E6E6E6;
            border-radius: 10px;
            color: #B2B2B2;
            &.in {
                border-color: #F3DDAA;
                background: #FFF9F1;
                color: #E6B365;
            }
        }
        .btn {
            width: 128px;
            height: 30px;
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            border-radius: 15px;
            color: #999999;
            margin: 0 auto;
            &.in {
                color: #1890FF;
                border-color: #1890FF;
            }
        }
	}
}
</style>
